// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { Edges } from '../../types';
import styles from './Feed.module.scss';

type Props = {
  edges: Edges,
};

const Feed = ({ edges }: Props) => (
  <div className={styles['feed']}>
    {edges.map((edge) => {
      const isSocialImageExist = !!edge.node.frontmatter.socialImage;
      const SafeGatsbyImage = () =>
        isSocialImageExist ? (
          <GatsbyImage
            alt={`${edge.node.frontmatter.title}-social-image`}
            image={
              edge.node.frontmatter.socialImage.childImageSharp.gatsbyImageData
            }
            placeholder='blurred'
          />
        ) : null;
      const timeToRead = edge?.node?.timeToRead ?? 0;
      const TimeToRead = () =>
        timeToRead ? (
          <>
            {timeToRead} min read
            <span className={styles['feed__item-meta-divider']}>·</span>
          </>
        ) : null;
      const isPortfolio = !!(edge?.node?.frontmatter?.portfolio ?? null);
      return (
        <div className={styles['feed__item']} key={edge.node.fields.slug}>
          <div className={styles['feed__item-meta']}>
            <time
              className={styles['feed__item-meta-time']}
              dateTime={new Date(edge.node.frontmatter.date).toLocaleDateString(
                'en-US',
                { year: 'numeric', month: 'short', day: 'numeric' },
              )}
            >
              {new Date(edge.node.frontmatter.date).toLocaleDateString(
                'en-US',
                { month: 'short', day: 'numeric' },
              )}
            </time>
            <span className={styles['feed__item-meta-divider']}>·</span>
            <TimeToRead />
            <span className={styles['feed__item-meta-category']}>
              <Link
                to={
                  isPortfolio
                    ? edge.node.fields.portfolioSlug
                    : edge.node.fields.categorySlug
                }
                className={styles['feed__item-meta-category-link']}
              >
                {isPortfolio
                  ? edge.node.frontmatter.portfolio
                  : edge.node.frontmatter.category}
              </Link>
            </span>
          </div>
          <h2 className={styles['feed__item-title']}>
            <Link
              className={styles['feed__item-title-link']}
              to={edge.node.fields.slug}
            >
              {edge.node.frontmatter.title}
            </Link>
          </h2>
          <p className={styles['feed__item-description']}>
            {edge.node.frontmatter.description}
          </p>
          <SafeGatsbyImage />
          <div className={styles['feed__item-readmore']}>
            <Link to={edge.node.fields.slug}>Read more</Link>
          </div>
        </div>
      );
    })}
  </div>
);

export default Feed;
